import MatrixGallery_PolaroidCustomHeightSkinComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/skinComps/PolaroidCustomHeightSkin/PolaroidCustomHeightSkin.skin';
import MatrixGallery_PolaroidCustomHeightSkinController from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/MatrixGallery.controller';


const MatrixGallery_PolaroidCustomHeightSkin = {
  component: MatrixGallery_PolaroidCustomHeightSkinComponent,
  controller: MatrixGallery_PolaroidCustomHeightSkinController
};


export const components = {
  ['MatrixGallery_PolaroidCustomHeightSkin']: MatrixGallery_PolaroidCustomHeightSkin
};

